<template>
 <div id="camera-modal">
        <video autoplay playsinline ref="video" style="visibility: hidden;"></video>
        <canvas ref="cvVideo" class="canvas"></canvas>
        <canvas ref="cv" class="canvas"></canvas>
 
        <v-btn class="take-picture-button" fab @click="processImg">
          <v-icon color="primary darken-4">mdi-camera</v-icon>
        </v-btn>

         <div id="instruction" class="text-center natural--text  text-title-3 pb-5">
            กรุณาวางอุปกรณ์ให้อยู่ในกรอบที่กำหนด
        </div>
         <div id="instruction2" class="text-center natural--text  text-title-3 pa-15">
           เลี่ยงที่แสงสะท้อน หรือที่มืดเกินไป
ควรวางอุปกรณ์ราบกับพื้น
เพื่อให้อ่านตัวอักษรได้ชัดเจน
        </div> 
      <CameraLoadingCard :overlay="loading" />
      <v-dialog v-model="dialog" persistent>
      <CameraFailCard @onClose="dialog=false" @onSkip="$router.push({ name: 'info'})"/>
      </v-dialog>
</div>
</template>

<script>
// import CameraNavBar from "@/components/camera/CameraNavBar";
import CameraLoadingCard from "@/components/camera/CameraLoadingCard";
import CameraFailCard from '@/components/camera/CameraFailCard'
import { ocrMeddevice}  from "@/api"
import { mapFields } from 'vuex-map-fields';
// eslint-disable-next-line no-unused-vars
import adapter from 'webrtc-adapter'

export default {
  components: {
    // CameraNavBar,
    CameraLoadingCard,
    CameraFailCard
  },
  data() {
    return {
      overlay: true,
      mediaStream: null,
      switchCamera: false,
      snackmessage: "",
      snackbar: false,
      isChrome57or58: null,
      loading: false,
      dialog: false,
      requestId: null
    };
  },
  computed: {
    ...mapFields(['citizenObj'])
  },
  destroyed() {
    if(this.mediaStream){
    this.mediaStream.getTracks().forEach((track) => {
      track.stop();
    });
    }
  },
  mounted() {
    let supports = navigator.mediaDevices.getSupportedConstraints();
    let switchCamera = false;
    if (supports["facingMode"] === true) {
      switchCamera = true;
    }
    const constraints = {
      audio: false,
      video: {
        facingMode: switchCamera ? "environment" : "user",
      },
    };

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((mediaStream) => {
          this.mediaStream = mediaStream;
          this.$refs.cvVideo.width = screen.width;
          this.$refs.cvVideo.height = screen.height;

          this.$refs.video.srcObject = mediaStream;
         
          this.$refs.video.play();
          this.$refs.video.addEventListener("loadedmetadata", () => {
            this.renderFrame()
          })

           this.$refs.cv.width = screen.width;
           this.$refs.cv.height = screen.height;
            let ctx = this.$refs.cv.getContext("2d");
            ctx.fillStyle = "rgba(0,0,0,0.8)";
            ctx.fillRect(0, 0, screen.width, screen.height / 5);
            ctx.fillRect(
              0,
              (screen.height / 3) * 2,
              screen.width,
              screen.height
            );

            var img = new Image();
            img.onload = function () {
              ctx.drawImage(
                img,
                0,
                0,
                img.naturalWidth,
                img.naturalHeight,
                0,
                0,
                img.naturalWidth/2,
                img.naturalHeight/2
              );
            };
            img.src = require("@/assets/stick.svg");
        })
        .catch();
    }else{
       this.$emit('onClose')
    }
  },
  methods: {
   onStopCamera() {
      if(this.mediaStream){
      this.mediaStream.getTracks().forEach((track) => {
      track.stop();
      });
      cancelAnimationFrame(this.requestId)
    }
   },
   calculateSize(srcSize, dstSize) {
    var srcRatio = srcSize.width / srcSize.height;
    var dstRatio = dstSize.width / dstSize.height;
    if (dstRatio > srcRatio) {
      return {
        width:  dstSize.height * srcRatio,
        height: dstSize.height
      };
    } else {
      return {
        width:  dstSize.width,
        height: dstSize.width / srcRatio
      };
    }
   },
   drawCanvas () {
        let ctx = this.$refs.cv.getContext("2d");
        ctx.drawImage(this.$refs.video, 0, 0, this.$refs.cv.width, this.$refs.cv.height)
   },
   renderFrame () {
     this.requestId = requestAnimationFrame(this.renderFrame)
     let ctxVideo = this.$refs.cvVideo.getContext("2d");
     const inputWidth = this.$refs.video.videoWidth;
     const inputHeight = this.$refs.video.videoHeight;
     const inputImageAspectRatio = this.$refs.video.videoWidth / this.$refs.video.videoHeight;
     const outputImageAspectRatio = screen.width / screen.height;
        let outputWidth = inputWidth;
        let outputHeight = inputHeight;
        if (inputImageAspectRatio > outputImageAspectRatio) {
          outputWidth = inputHeight * outputImageAspectRatio;
        } else if (inputImageAspectRatio < outputImageAspectRatio) {
          outputHeight = inputWidth / outputImageAspectRatio;
        }
          // calculate the position to draw the image at
          const outputX = (inputWidth - outputWidth ) * 0.5;
          const outputY = (inputHeight - outputHeight ) * 0.5;
          ctxVideo.drawImage( this.$refs.video,outputX,outputY,outputWidth,outputHeight,0,0,screen.width,screen.height);
   },
   async processImg() {
      this.loading = true
      //const canvas = document.createElement('canvas')
      //canvas.width = this.$refs.video.videoWidth
      //canvas.height = this.$refs.video.videoHeight
      //canvas.getContext('2d').drawImage(this.$refs.cvVideo,0,(this.$refs.cvVideo.height)/5,this.$refs.cvVideo.width,(this.$refs.cvVideo.height)/3,0,0,this.$refs.cvVideo.width,(this.$refs.cvVideo.height)/3)
      // var dataURL = canvas.toDataURL()
               let dataURL = this.$refs.cvVideo.toDataURL()
               dataURL.replace('data:image/png;base64,','')
               let base64ContentArray = dataURL.split(",")   
               let base64Data = base64ContentArray[1]
               console.log(base64Data)
               let imageBlob = await new Promise(resolve => this.$refs.cvVideo.toBlob(resolve, 'image/png'));
               let formData = new FormData();
               formData.append("image", imageBlob, "image.png");
               this.onStopCamera()
               ocrMeddevice({modelId: '11d522c1-d4c3-42f9-ac00-1723a662d112', appKey: '7badCjWLfn0dsh7rK1N1_YUuc0f_YElQ'}, formData, message => {
                   // console.log(message.data.result)
                   if (message.data.result) {
                      if (message.data.result[0].message === 'Success') {
                          this.$emit('onSuccess',message.data.result[0].prediction)
                        
                      }
                   } else {
                     this.$emit('onClose')
                   }
                   this.loading = false
               }, error => {
                  this.$emit('onClose')
                   console.log(error)
               })
    }
  },
};
</script>

<style lang="scss" scoped>
#camera-modal {
        width: 100%;
        height: 100%;
        top: 20;
        left: 0;
        position: absolute;
        background-color: white;
        z-index: 1;
        video {
    height: 100%;
    z-index: 0;
  }
}
.canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  max-height: 100%;
}
#instruction {
  position: absolute;
  margin: auto;
  width:100%;
  top: 70px;
  z-index: 0;
}
#instruction2{
  position: absolute;
  margin: auto;
  width: 100%;
  bottom: 50px;
  z-index: 0;
}
.take-picture-button {
  display: block;
  margin: auto;
  bottom: 80px;
}
#videoContainer {
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  video {
    height: 100%;
    -ms-transform: translateX(-25%);
    -webkit-transform: translateX(-25%);
    transform: translateX(-25%);
    z-index: 0;
  }
}
</style>