<template>
  <v-btn
    v-bind="$attrs"
    v-on="inputListeners"
    x-large
    block
    outlined
    v-on:click ="onClick"
   :style="{
      color: active? '#245DDB !important' : '#C7C9D9 !important',
      'border-color': active? '#245DDB !important' : '#C7C9D9 !important',
    }"
    ><span class="primary--text text--darken-4">{{title}}</span><v-spacer />
  <v-icon :color="active? 'primary': 'natural darken-1'">mdi-check-circle</v-icon>
  </v-btn>
</template>

<script>
export default {
  model: {
    prop: 'active',
    event: 'change'
  },
  props: ['title','active','index'],
  methods: {
    onClick() {
      if(this.active){
      this.$emit('update:active', false)
      }else{
this.$emit('update:active', true)
      }
      this.$emit('togle',this.index)
    }
  },
  computed: {
    inputListeners: function () {
      var vm = this
      // `Object.assign` merges objects together to form a new object
      return Object.assign({},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit('input', event)
          }
        }
      )
    }}
};
</script>

<style lang="scss" scoped>
 .v-btn--outlined {
    border: 2px solid currentColor !important;
}
</style>
