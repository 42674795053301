<template>
    <div>
      <label class="text-title-3 primary--text text--darken-4"> {{title}} </label>
        <v-img v-if="fileEncode" :src="fileEncode" width="100%" contain/>
<!--          <v-file-input
         outlined
         large 
         color="primary"
         class="ma-0 pa-0"
         :rules="rules"
         accept="image/png, image/jpeg, image/bmp"
         placeholder="คลิกที่นี่เพื่อถ่ายภาพ"
         prepend-icon="mdi-camera"
         @change="fileChange"
  ></v-file-input> -->
      <div class="mt-2">{{value}}</div>
      <v-btn outlined class="mt-2" block color="primary" @click="uploadDialog = true">คลิกที่นี่เพื่อถ่ายภาพ</v-btn>
      <v-dialog v-model="uploadDialog" fullscreen>
        <CaptureCard @onClose="uploadDialog=false" @onSuccess="onSuccess" :key="uploadDialog" />
    </v-dialog>
    </div>
    <!-- </label> -->
</template>

<script>
import Reduce from "image-blob-reduce";
import CaptureCard from "@/components/ocr/StickOverlayCard";

export default {
 model: {
    prop: 'value',
    event: 'change'
  },
 props: {
   title: {
      type: String
   },
   value: {
      type: String
   }
 },
 components: {
   CaptureCard,
 },
 data () {
  return {
    rules:  [value => !value || value.size < 15000000 || 'file size should be less than 15  MB!'],
    uploadDialog: false,
    fileEncode: null,
    fileName: null
  }
 },
 methods: {
     onSuccess (data) {
       this.uploadDialog=false
       this.fileEncode = data.fileEncode
       this.$emit('change', data.fileName)
     },
     fileChange (file) {
        if (file) {
            const rec = new Reduce()
            rec.toBlob(file, { max: 500 }).then(blob => { 
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onload = e => {
                 this.$emit('change', e.target.result)
                }
            }
            )
        }
     }
 },
}
</script>


<style lang="scss" scoped>
.v-btn {
  border-radius: 6px;
}
</style>