<template>
 <div id="camera-modal">
       <v-app-bar absolute color="transparent" elevation="0">
          <v-app-bar-nav-icon @click="onClose()"  data-cy="CameraNavBar-backward-btn">
       <template slot="default">
           <v-btn icon color="natural" ><v-icon>mdi-arrow-left</v-icon></v-btn>
       </template>
     
         </v-app-bar-nav-icon>
            <v-spacer/>
  <v-menu offset-y :rounded="'0'">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon  v-bind="attrs" v-on="on"><v-icon color="natural">mdi-rotate-3d-variant</v-icon></v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in devices"
          :key="index"
          @click="start(item.value)"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
       </v-app-bar>
        <video autoplay playsinline ref="video" style="visibility: hidden;"></video>
        <canvas ref="cvVideo" class="canvas"></canvas>
        <canvas ref="cv" class="canvas"></canvas>
 
        <v-btn class="take-picture-button" fab @click="uploadImg">
          <v-icon color="primary darken-4">mdi-camera</v-icon>
        </v-btn>

         <!-- <div id="instruction" class="text-center natural--text  text-title-3 pb-5">
            กรุณาวางอุปกรณ์ให้อยู่ในกรอบที่กำหนด
        </div> -->
         <!-- <div id="instruction2" class="text-center natural--text  text-title-3 pa-15">
               กรุณาวางอุปกรณ์ให้อยู่ในกรอบที่กำหนด
           เลี่ยงที่แสงสะท้อน หรือที่มืดเกินไป
ควรวางอุปกรณ์ราบกับพื้น
เพื่อให้อ่านตัวอักษรได้ชัดเจน
        </div>  -->
      <CameraLoadingCard :overlay="loading" />
      <v-dialog v-model="dialog" persistent>
      <CameraFailCard @onClose="dialog=false" @onSkip="$router.push({ name: 'info'})"/>
      </v-dialog>
</div>
</template>

<script>
// import CameraNavBar from "@/components/camera/CameraNavBar";
import CameraLoadingCard from "@/components/camera/CameraLoadingCard";
import CameraFailCard from '@/components/camera/CameraFailCard'
// eslint-disable-next-line no-unused-vars
import adapter from 'webrtc-adapter'
import { postFormResultFile } from "@/api/"

export default {
  components: {
    // CameraNavBar,
    CameraLoadingCard,
    CameraFailCard
  },
  data() {
    return {
      overlay: true,
      mediaStream: null,
      switchCamera: false,
      snackmessage: "",
      snackbar: false,
      isChrome57or58: null,
      loading: false,
      dialog: false,
      requestId: null,
      devices: []
    };
  },
  destroyed() {
    if(this.mediaStream){
     this.mediaStream.getTracks().forEach((track) => {
      track.stop();
    });
    }
  },
  async mounted() {
      console.log('onMouted')
      await this.getCameraLists()
      this.start()
  },
  methods: {
    start(deviceId) {
      if(this.mediaStream){
          this.mediaStream.getTracks().forEach((track) => {
          track.stop();
        })
         this.$refs.video.removeEventListener("loadedmetadata", () => {
             this.renderFrame()
           })
      }

      let supports = navigator.mediaDevices.getSupportedConstraints();
      let switchCamera = false;
      if (supports["facingMode"] === true) {
        switchCamera = true;
      }

      const constraints = (deviceId) ? {
      audio: false,
      video: {deviceId: deviceId ? {exact: deviceId} : undefined}
      } :  {
      audio: false,
      video: {
        facingMode: switchCamera ? "environment" : "user",
      },
      }
        console.log(constraints)
        navigator.mediaDevices
        .getUserMedia(constraints)
        .then((mediaStream) => {
          this.mediaStream = mediaStream;
          this.$refs.cvVideo.width = screen.width;
          this.$refs.cvVideo.height = screen.height;
          this.$refs.video.srcObject = mediaStream;
          this.$refs.video.play();
          this.$refs.video.addEventListener("loadedmetadata", () => {
            this.renderFrame()
             
          })

          this.$refs.cv.width = screen.width;
          this.$refs.cv.height = screen.height;
            let ctx = this.$refs.cv.getContext("2d");
            ctx.fillStyle = "rgba(0,0,0,0.8)";
            ctx.fillRect(0, 0, screen.width, 48);
            ctx.fillRect(
              0,
              (screen.height / 3) * 2.5,
              screen.width,
              screen.height
            );

            var img = new Image();
            img.onload = function () {
              const ratio = img.naturalWidth / img.naturalHeight
              const height = screen.height - 48 - (screen.height - ((screen.height / 3) * 2.5)) - 100
              const width = height * ratio
              ctx.drawImage(
                img,
                0,
                0,
                img.naturalWidth,
                img.naturalHeight,
                ((screen.width-width) / 2),
                48 + 50,
                width,
                height
              );
            };
            img.src = require("@/assets/stick.svg");
        }).catch((error=>{
          console.log(error)
          this.$emit('onClose')
        }))
   },  
    uploadImg () {
        this.loading = true
        let dataURL = this.$refs.cvVideo.toDataURL()
        let arrBase64 = dataURL.split(",")   
        const site = this.$offlineStorage.get("site");
        const tenant = this.$offlineStorage.get("tenant");
        const form = this.$offlineStorage.get("currentForm");
        console.log(form)
        console.log(dataURL)
        let data = {
            tenantId: tenant.tenantId,
            siteId: site.siteId,
            formId: (form.formId) ? form.formId: form.arrResult[0].formId,
            fileType: arrBase64[0].match(/:(.*?);/)[1],
            fileEncode: arrBase64[1]
        }
        postFormResultFile(data, message => {
            if(message) {
                console.log(message)
                this.onStopCamera()
                this.$emit('onSuccess',{fileName: message.data.result.fileName, fileEncode: dataURL})
            }
            this.loading = false
        },error=>{
                console.log(error)
                this.loading = false
                this.onStopCamera()
                this.$emit('onClose')
        })
    },
    onStopCamera() {
      if(this.mediaStream){
      this.mediaStream.getTracks().forEach((track) => {
      track.stop();
      });
      window.cancelAnimationFrame(this.requestId)
      this.requestId = undefined
    }
   },
   onClose() {
     this.onStopCamera()
     this.$emit('onClose')
   },
    async getCameraLists() {
        const askPermission = await navigator.mediaDevices.getUserMedia({video:{width:4096,height:2160}})
        askPermission.getTracks().forEach((track) => {
            track.stop();
        });
        navigator.mediaDevices.enumerateDevices().then(this.gotDevices).catch(this.handleError)
    },
    gotDevices(deviceInfos) {
       for (let i = 0; i !== deviceInfos.length; ++i) {
        const deviceInfo = deviceInfos[i];
        if (deviceInfo.kind === 'videoinput') {
        this.devices.push({text: deviceInfo.label, value: deviceInfos[i].deviceId})
        console.log('Device Info source/device: ', deviceInfo);
        } else {
        console.log('Some other kind of source/device: ', deviceInfo);
        }
        }
    },
    handleError(error)  {
        console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
           this.$gtag.exception({
                    'description': error,
                    'fatal': false
                     })
    },
   renderFrame () {
     if (this.$refs.cvVideo) {
      setTimeout(() => {
          this.requestId = window.requestAnimationFrame(this.renderFrame)
          //console.log(this.requestId)
      }, 1000 / 25);

    
     if (this.$refs.video.readyState === this.$refs.video.HAVE_ENOUGH_DATA) {
     let ctxVideo = this.$refs.cvVideo.getContext("2d");
     const inputWidth = this.$refs.video.videoWidth;
     const inputHeight = this.$refs.video.videoHeight;
     const inputImageAspectRatio = this.$refs.video.videoWidth / this.$refs.video.videoHeight;
     const outputImageAspectRatio = screen.width / screen.height;
        let outputWidth = inputWidth;
        let outputHeight = inputHeight;
        if (inputImageAspectRatio > outputImageAspectRatio) {
          outputWidth = inputHeight * outputImageAspectRatio;
        } else if (inputImageAspectRatio < outputImageAspectRatio) {
          outputHeight = inputWidth / outputImageAspectRatio;
        }
          // calculate the position to draw the image at
          const outputX = (inputWidth - outputWidth ) * 0.5;
          const outputY = (inputHeight - outputHeight ) * 0.5;
          //console.log(ctxVideo)
          //console.log(ctxVideo)
          //console.log(outputWidth)
          //console.log(outputHeight)
          //console.log(screen.width)
          //console.log(screen.height)
          ctxVideo.drawImage( this.$refs.video,outputX,outputY,outputWidth,outputHeight,0,0,screen.width,screen.height);
     }
      // this.requestId = window.requestAnimationFrame(this.renderFrame)

     }
   },
  },
};
</script>

<style lang="scss" scoped>
#camera-modal {
        width: 100%;
        height: 100%;
        top: 20;
        left: 0;
        position: absolute;
        background-color: white;
        z-index: 1;
        video {
    height: 100%;
    z-index: 0;
  }
}
.canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  max-height: 100%;
}
#instruction {
  position: absolute;
  margin: auto;
  width:100%;
  top: 70px;
  z-index: 0;
}
#instruction2{
  position: absolute;
  margin: auto;
  width: 100%;
  bottom: 50px;
  z-index: 0;
}
.take-picture-button {
  display: block;
  margin: auto;
  bottom: 80px;
  z-index: 10;
}
#videoContainer {
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  video {
    height: 100%;
    -ms-transform: translateX(-25%);
    -webkit-transform: translateX(-25%);
    transform: translateX(-25%);
    z-index: 0;
  }
}
</style>