<template>
  <v-main class="natural lighten-3">
    <v-container fluid class="natural lighten-3">
      <v-row>
        <v-col cols="4"><StepCardInActive :title="'ข้อมูลส่วนตัว'" /></v-col>
        <v-col cols="4"><StepCardActive :title="'ข้อมูลสุขภาพ'" /></v-col>
        <v-col cols="4"><StepCardInActive :title="'สำเร็จ'" /></v-col>
      </v-row>
    </v-container>
    <v-container fluid class="ma-0 pa-0">
      <v-row class="ma-0 pa-0">
        <v-col cols="12" class="ma-0 pa-0">
          <v-card elevation="0" tile>
            <v-card-title>ข้อมูลสุขภาพ</v-card-title>
            <v-card-text>
              <TextField
                disabled
                v-model="citizenObj.id_number"
                v-mask="'#-####-#####-##-#'"
                :title="'บัตรประจำตัวประชาชน'"
              />
              <TextField
                disabled
                :title="'ชื่อ-นามสกุล'"
                v-model="citizenObj.name_th"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" class="ma-0 pl-0 pr-0">
          <v-card elevation="0" tile>
            <v-card-title
              ><v-row dense
                ><v-col cols="10">{{ this.form.formTypeName }}</v-col
                ><v-col cols="1"></v-col></v-row
            ></v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row dense>
                  <template   v-for="(item, index) in form.schema.fields">
                  <v-col
                    :key="index"
                    cols="12"
                    v-if="item.visible"
                  >
                    <SelectMultiWidget
                     v-if="item.type === 'select' && visibleCondtion(item) && item.multiple && item.visible"
                      v-bind="item"
                      v-model="item.value"
                    />
                    <!--.schema.fields -->
                    <SelectWidget
                      v-if="item.type === 'select' && visibleCondtion(item) && !item.multiple && item.visible"
                      v-bind="item"
                      v-model="item.value"
                    />
                    <!-- <NumberWidget v-if="item.type==='number'" :question="item"/> -->
                    <TextWidget
                      class="pa-0 ma-0"
                      v-if="item.type === 'text' && visibleCondtion(item) && item.visible"
                      v-bind="item"
                      v-model="item.value"
                      @click:append="inputAction(item)"
                    />

                    <ImageUploadWidget
                      class="pa-0 ma-0"
                      v-if="item.type === 'image' && visibleCondtion(item) && item.visible"
                      :title="item.title"
                      v-model="item.value"/>

                        <SectionWidget
                      v-if="item.type === 'section' && visibleCondtion(item) && item.visible"
                      :title="item.title"
                      v-model="item.value"
                    />
                  </v-col>
                  </template>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" class="ma-0 pl-0 pr-0">
          <v-card tile elevation="0">
            <v-card-text>
              <v-row>
                <v-col cols="12" class="pt-2">
                  <v-btn
                    block
                    large
                    outlined
                    color="primary"
                    @click="confirmDraftDialog = true"
                    ><span class="text-title-2">บันทึกร่าง</span></v-btn
                  >
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-btn
                    block
                    large
                    color="primary text-title-2"
                    @click="validateData"
                    >ส่งข้อมูล</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="confirmDialog" width="327">
      <ConfirmCard @onClose="confirmDialog = false" @onConfirm="sendData()" />
    </v-dialog>
    <v-dialog v-model="confirmDraftDialog" width="327">
      <ConfirmDraftCard
        @onClose="confirmDraftDialog = false"
        @onConfirm="saveDraft()"
      />
    </v-dialog>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="ocrDialog" fullscreen>
          <CaptureCard @onClose="ocrDialog=false" @onSuccess="ocrSuccess" :key="ocrDialog" />
    </v-dialog>
  </v-main>
</template>

<script>
import StepCardActive from "@/components/form/StepCardActive";
import StepCardInActive from "@/components/form/StepCardInactive";
import TextField from "@/components/form/TextField";
import ConfirmCard from "@/components/form/ConfirmCard";
import ConfirmDraftCard from "@/components/form/ConfirmDraftCard";
import SelectWidget from "@/components/form/SelectWidget";
import SelectMultiWidget from "@/components/form/SelectMultiWidget";
import TextWidget from "@/components/form/TextWidget";
import CaptureCard from "@/components/ocr/CaptureCard";
import ImageUploadWidget from "@/components/form/ImageUploadWidget";
import SectionWidget from "@/components/form/SectionWidget";
// import NumberWidget from "@/components/form/NumberWidget";
// import TextArea from "@/components/form/TextArea";
import { postFormResult } from "@/api/";
import { mask } from "vue-the-mask";
import { mapFields } from "vuex-map-fields";
import { v4 as uuidv4 } from "uuid";

const required = (msg) => (v) => !!v || msg;

export default {
  components: {
    StepCardActive,
    StepCardInActive,
    TextField,
    ConfirmCard,
    SelectWidget,
    TextWidget,
    ConfirmDraftCard,
    SelectMultiWidget,
    CaptureCard,
    ImageUploadWidget,
    SectionWidget
    //SelectMultiWidget
    //NumberWidget
    // TextArea,
  },
  computed: {
    ...mapFields(["citizenObj", "formOffline", "formDraft"]),
  },
  directives: { mask },
  data() {
    return {
      cid: null,
      confirmDialog: false,
      confirmDraftDialog: false,
      ocrDialog: false,
      schema: null,
      loading: null,
      valid: false,
      role: null,
      form: null,
      currentItem: null,
      form2: [
        {
          title: "เพศ",
          name: "gender",
          required: true,
          choices: ["ชาย", "หญิง"],
          type: "select",
        },
        {
          title: "น้ำหนัก",
          name: "weight",
          placeholder: "กิโลกรัม",
          required: true,
          type: "text",
          inputType: "number",
          value: 90,
        },
        {
          title: "ความดันโลหิตตัวบน",
          name: "sys",
          placeholder: "mmHg",
          required: true,
          type: "text",
          inputType: "number",
        },
        {
          title: "ความดันโลหิตตัวล่าง",
          name: "dia",
          placeholder: "mmHg",
          required: true,
          type: "text",
          inputType: "number",
        },
        {
          title: "อัตราการเต้นหัวใจ",
          name: "pulse",
          placeholder: "bpm",
          required: true,
          type: "text",
          inputType: "number",
        },
        {
          title: "วิธีตรวจน้ำตาล",
          name: "dtx",
          required: true,
          choices: ["DTX", "FBS"],
          type: "select",
        },
        {
          title: "การตรวจวัดน้ำตาล",
          name: "method",
          required: true,
          choices: ["โดยอดอาหาร", "โดยไม่อดอาหาร"],
          type: "select",
        },
        {
          title: "ผลตรวจวัดน้ำตาล (mg/dl)",
          name: "bg",
          placeholder: "mg/dl",
          required: true,
          type: "text",
          inputType: "number",
        },
        {
          title: "ประวัติการสูบบุหรี่",
          name: "smoke",
          required: true,
          choices: [
            "ไม่สูบบุหรี่",
            "นานๆครั้ง",
            "เป็นครั้งคราว",
            "สูบเป็นประจำ",
          ],
          type: "select",
        },
        {
          title: "ประวัติการดื่มแอลกอฮอล",
          name: "alh",
          required: true,
          choices: ["ไม่ดื่ม", "นานๆครั้ง", "เป็นครั้งคราว", "ดื่มเป็นประจำ"],
          type: "select",
        },
        {
          title: "ญาติสายตรงของท่านมีประวัติการเจ็บป่วย",
          name: "familyDesease",
          required: true,
          choices: ["ไม่มีโรค", "มีโรค", "ไม่ทราบ"],
          type: "select",
          value: "ไม่มีโรค",
        },
        {
          title: "โรคประจำตัวของญาติท่าน",
          name: "familyNCD",
          required: true,
          visibleIf: "{familyDesease}='มีโรค'",
          choices: ["เบาหวาน", "ความดัน"],
          type: "select",
        },
      ],
    };
  },
  methods: {
    inputAction (value) {
      if (value.action) {
        this.currentItem = value
        switch(value.action.name){
          case 'ocr':
            this.ocrDialog = true
            break;
        }
      }
    },
    ocrSuccess (data) {
      this.ocrDialog = false
      if (this.currentItem.action.type === 'bp') {
      for(let i = 0; i< data.length; i++) {
        switch (data[i].label) {
          case 'SYS':
            this.form.schema.fields.filter( val => val.name === this.currentItem.action.sys)[0].value = Number(data[i].ocr_text)
            break
          case 'DIA':
            this.form.schema.fields.filter( val => val.name === this.currentItem.action.dia)[0].value = Number(data[i].ocr_text)
            break
           case 'PULSE':
            this.form.schema.fields.filter( val => val.name === this.currentItem.action.pulse)[0].value = Number(data[i].ocr_text)
            break
        }
      }
      }
    },
    buildSchema() {
      this.form.schema.fields.map((obj) => {
        if (obj.required) {
          obj.rules = [required("กรุณากรอกข้อมูล")];
        }

        if (obj.visibleIf) {
          let property = obj.visibleIf.match(/\{(.*?)\}/);
          let val = obj.visibleIf.match(/'([^']+)'/);
          obj.conditionprop = property[1].trim()
          obj.conditionval = val[1].trim()
        }

        if (obj.type === 'location') {
          
           if ('geolocation' in navigator) {
             const options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
             }
              navigator.geolocation.getCurrentPosition((pos) => {
                obj.value = pos.coords.latitude + ',' + pos.coords.longitude
                console.log(pos.coords)
              },function error(err) {
                console.warn(`ERROR(${err.code}): ${err.message}`);
              },options)
           }
        }
      });
    },
    validateData() {
      if (this.$refs.form.validate() & this.validateSelectWidget()) {
        this.confirmDialog = true;
      }else{
        this.$vuetify.goTo(500, 'number')
      }
    },
    validateSelectWidget() {
      let validate = true;
      for (let i = 0; i < this.form.schema.fields.length; i++) {
        if (
          this.form.schema.fields[i].type === "select" &&
          this.form.schema.fields[i].required
        ) {
          if (!this.form.schema.fields[i].value) {
            if(this.visibleCondtion(this.form.schema.fields[i])){
              this.form.schema.fields[i].error = true;
              validate = false;
              console.log(this.form.schema.fields[i])
              console.log('validate false')
            }else{
              this.form.schema.fields[i].error = false;
            }
          }else{
             this.form.schema.fields[i].error = false;
          }
        }
      }
      return validate;
    },
    changeValue() {
      let data = this.form.schema.fields.filter(
        (obj) => obj.name === "familyDesease"
      );
      if (data[0].value === "มีโรค") {
        data[0].value = "ไม่มีโรค";
      } else {
        data[0].value = "มีโรค";
      }
    },
    visibleCondtion(item) {
      if (!item.visible) return false;
      if (!item.visibleIf) return true;
      let filterObj = this.form.schema.fields.filter(
        (obj) => obj.name === item.conditionprop
      );
      if (!filterObj.length) return true;
      if (filterObj[0].value && (filterObj[0].value.title === item.conditionval)) {
        return true;
      } else {
        return false;
      }
    },
    buildModel() {
      let model = {};
      this.form.schema.fields.map((obj) => {
        model[obj.name] = obj.value;
      });
      return model;
    },
    sendData() {
      this.confirmDialog = false;
      this.role = this.$offlineStorage.get("role"); // replace(/^(\+)|\D/g, '$1'
      const site = this.$offlineStorage.get("site");
      const tenant = this.$offlineStorage.get("tenant");
      this.citizenObj.id_number = this.citizenObj.id_number.replace(/^(\+)|\D/g, '$1')
      let data = {
        tenantId: tenant.tenantId,
        siteId: site.siteId,
        arrResult: [
          {
            ...this.form,
            ...this.citizenObj,
            ...{ formResultId: uuidv4() },
            ...{ model: this.buildModel() },
          },
        ],
      };
      const size = new TextEncoder().encode(JSON.stringify(data)).length
      const kiloBytes = size / 1024;
      console.log(kiloBytes)
      if (this.isOnline) {
        this.loading = true;
        postFormResult(
          { data },
          (message) => {
            if (message.data.code === 1) {
              this.$offlineStorage.set("currentForm",null);
              this.$router.replace("/form/complete");
            }
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            console.log(error);
          }
        );
      } else {
        // Save data to localstorage
        this.loading = true;
        let formOffline = this.$offlineStorage.get("formOffline");
        if (formOffline) {
          formOffline.push([this.role.uuid.mobile, data]);
        } else {
          formOffline = [];
          formOffline.push([this.role.uuid.mobile, data]);
        }
        this.$offlineStorage.set("formOffline", formOffline);
        this.loading = false;
        this.$router.replace("/form/complete");
      }
    },
    saveDraft() {
      this.confirmDraftDialog = false;
      this.role = this.$offlineStorage.get("role");
      const site = this.$offlineStorage.get("site");
      const tenant = this.$offlineStorage.get("tenant");
      let data = {
        tenantId: tenant.tenantId,
        siteId: site.siteId,
        arrResult: [
          {
            ...this.form,
            ...this.citizenObj,
            ...{ formResultId: uuidv4() },
            ...{ model: this.buildModel() },
          },
        ],
      };
      // Save data to localstorage
      let draft = this.$offlineStorage.get("draft");
      if (draft) {
        draft.push([this.role.uuid.mobile, data]);
      } else {
        draft = [];
        draft.push([this.role.uuid.mobile, data]);
      }
      this.$offlineStorage.set("draft", draft);
      this.$router.replace("/form/complete");
    },
    fetchData() {
      this.loading = true;
      this.form = this.$offlineStorage.get("currentForm");
      this.loading = false;
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
        this.buildSchema();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  border-radius: 6px;
}
</style>